import { SectionContent } from '../OldGeneralComponents';
import { Text2XL, TextXL } from '../GeneralComponents';
import Image from 'next/image';
import React from 'react';
import locale from '../../locales/en/NativeLanding';
const logosList = [
  { width: 147, src: 'bbc.png' },
  { width: 146, src: 'unicef.png' },
  { width: 140, src: 'micrsft.svg' },
  { width: 120, src: 'Cisco.svg' },
  { width: 242, src: 'bd.png' },
  { width: 129, src: 'gojek.png' },
  { width: 107, src: 'google.svg' },
  { width: 100, src: 'zoho.svg' },
  { width: 135, src: 'fox.svg' },
  { width: 141, src: 'bcc.png' }
];

const Logos = (): JSX.Element => (
  <div className="relative flex flex-col justify-center pb-[32px] pt-[64px]">
    {/* <SectionContent className=""> */}
    <Text2XL className="!text-[20px] mx-auto mb-[24px] text-center text-grey-500">
      Trusted by organizations around the world
    </Text2XL>
    <div className="mx-auto grid max-w-[800px] grid-cols-2 gap-6 md:grid-cols-5 lg:grid-cols-5">
      {logosList.map((logo, index) => (
        <div className="flex w-full justify-center" key={index}>
          <Image
            alt={logo.src}
            className="flex self-center"
            src={`/index/logos/${logo.src}`}
            key={index}
            height={100}
            width={logo.width}
          />
        </div>
      ))}
    </div>
    {/* </SectionContent> */}
  </div>
);

export default Logos;
